import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Footers from '../components/footers';
import { serverURL } from '../constants';
import axios from 'axios';
import StyledText from '../components/styledText';

const PrivacyPolicy = () => {

    const [data, setData] = useState('');

    useEffect(() => {
        async function dashboardData() {
            const postURL = serverURL + `/api/policies`;
            const response = await axios.get(postURL);
            setData(response.data[0].privacy)
        }
        if (sessionStorage.getItem('TermsPolicy') === null && sessionStorage.getItem('PrivacyPolicy') === null) {
            dashboardData();
        } else {
            setData(sessionStorage.getItem('PrivacyPolicy'))
        }
    }, []);

    return (
        <div className='h-screen flex flex-col'>
            <Header isHome={false} className="sticky top-0 z-50" />
            <div className='dark:bg-black flex-1'>
                <div className='flex-1 flex items-center justify-center py-10 flex-col'>
                    <p className='text-center font-black text-4xl text-black dark:text-white'>นโยบายความเป็นส่วนตัว</p>
                    <div className='w-4/5 py-20'>
                    <b>นโยบายความเป็นส่วนตัวของ AIEdCreator</b>
<p>AIEdCreator เราให้ความสำคัญกับความเป็นส่วนตัวของคุณ นโยบายความเป็นส่วนตัวนี้อธิบายถึงวิธีที่เรารวบรวม ใช้ และปกป้องข้อมูลส่วนบุคคลของคุณเมื่อคุณใช้บริการของเรา</p>

<b>1. ข้อมูลที่เรารวบรวม</b>
<p>&nbsp;&nbsp;เราอาจรวบรวมข้อมูลส่วนบุคคลต่อไปนี้จากคุณ:</p>
<ul>
    <li>&nbsp;&nbsp;&nbsp;- ข้อมูลที่คุณให้โดยตรง เช่น ชื่อ ที่อยู่อีเมล และข้อมูลอื่น ๆ ที่คุณให้ไว้เมื่อลงทะเบียนหรือใช้บริการของเรา</li>
    <li>&nbsp;&nbsp;&nbsp;- ข้อมูลการใช้งาน เช่น ข้อมูลเกี่ยวกับวิธีที่คุณโต้ตอบกับบริการของเรา รวมถึงหน้าที่คุณเข้าชม เนื้อหาที่คุณสร้าง และคุณลักษณะที่คุณใช้</li>
    <li>&nbsp;&nbsp;&nbsp;- ข้อมูลอุปกรณ์ เช่น ประเภทของอุปกรณ์ที่คุณใช้ในการเข้าถึงบริการของเรา ระบบปฏิบัติการ และข้อมูลอื่น ๆ เกี่ยวกับอุปกรณ์ของคุณ</li>
</ul>

<b>2. วิธีที่เราใช้ข้อมูลของคุณ</b>
<p>&nbsp;&nbsp;เราอาจใช้ข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์ต่อไปนี้:</p>
<ul>
    <li>&nbsp;&nbsp;&nbsp;- เพื่อให้บริการและปรับปรุงบริการของเรา</li>
    <li>&nbsp;&nbsp;&nbsp;- เพื่อสื่อสารกับคุณ รวมถึงการส่งอีเมลเกี่ยวกับบริการของเรา การอัปเดต และข้อมูลส่งเสริมการขาย</li>
    <li>&nbsp;&nbsp;&nbsp;- เพื่อวิเคราะห์และทำความเข้าใจว่าผู้ใช้โต้ตอบกับบริการของเราอย่างไร</li>
    <li>&nbsp;&nbsp;&nbsp;- เพื่อปกป้องสิทธิ์ ทรัพย์สิน หรือความปลอดภัยของเรา ผู้ใช้ของเรา หรือผู้อื่น</li>
</ul>

<b>3. การเปิดเผยข้อมูลของคุณ</b>
<p>&nbsp;&nbsp;เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณให้กับบุคคลที่สามต่อไปนี้:</p>
<ul>
    <li>&nbsp;&nbsp;&nbsp;- ผู้ให้บริการที่ช่วยเราในการดำเนินธุรกิจ เช่น ผู้ให้บริการโฮสติ้ง ผู้ให้บริการชำระเงิน และผู้ให้บริการวิเคราะห์</li>
    <li>&nbsp;&nbsp;&nbsp;- หน่วยงานราชการหรือหน่วยงานบังคับใช้กฎหมาย หากเราเชื่อโดยสุจริตว่าการเปิดเผยดังกล่าวจำเป็นเพื่อให้สอดคล้องกับกฎหมาย ข้อบังคับ กระบวนการทางกฎหมาย หรือคำขอของรัฐบาล</li>
</ul>

<b>4. ความปลอดภัยของข้อมูล</b>
<p>&nbsp;&nbsp;&nbsp;เรามีมาตรการรักษาความปลอดภัยที่เหมาะสมเพื่อปกป้องข้อมูลส่วนบุคคลของคุณจากการเข้าถึง การใช้งาน การเปลี่ยนแปลง หรือการเปิดเผยโดยไม่ได้รับอนุญาต อย่างไรก็ตาม โปรดทราบว่าไม่มีวิธีการส่งข้อมูลหรือจัดเก็บข้อมูลทางอิเล็กทรอนิกส์ใดที่ปลอดภัย 100% และเราไม่สามารถรับประกันความปลอดภัยของข้อมูลของคุณได้อย่างสมบูรณ์</p>

<b>5. สิทธิ์ของคุณ</b>
<p>&nbsp;&nbsp;คุณมีสิทธิ์ต่อไปนี้เกี่ยวกับข้อมูลส่วนบุคคลของคุณ:</p>
<ul>
    <li>&nbsp;&nbsp;&nbsp;- สิทธิ์ในการเข้าถึงข้อมูลส่วนบุคคลของคุณ</li>
    <li>&nbsp;&nbsp;&nbsp;- สิทธิ์ในการแก้ไขข้อมูลส่วนบุคคลของคุณหากไม่ถูกต้องหรือไม่สมบูรณ์</li>
    <li>&nbsp;&nbsp;&nbsp;- สิทธิ์ในการลบข้อมูลส่วนบุคคลของคุณ</li>
    <li>&nbsp;&nbsp;&nbsp;- สิทธิ์ในการคัดค้านการประมวลผลข้อมูลส่วนบุคคลของคุณ</li>
</ul>

<b>6. การเก็บรักษาข้อมูล</b>
<p>&nbsp;&nbsp;&nbsp;เราจะเก็บรักษาข้อมูลส่วนบุคคลของคุณไว้ตราบเท่าที่จำเป็นเพื่อวัตถุประสงค์ที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้ หรือตามที่กฎหมายกำหนด</p>

<b>7. การเปลี่ยนแปลงนโยบายความเป็นส่วนตัวนี้</b>
<p>&nbsp;&nbsp;&nbsp;เราอาจอัปเดตหรือแก้ไขนโยบายความเป็นส่วนตัวนี้เป็นครั้งคราว การเปลี่ยนแปลงใด ๆ จะมีผลบังคับใช้เมื่อเราโพสต์นโยบายฉบับแก้ไขบนเว็บไซต์ของเรา</p>

<b>8. ติดต่อเรา</b>
<p>&nbsp;&nbsp;&nbsp;หากคุณมีคำถามใด ๆ เกี่ยวกับนโยบายความเป็นส่วนตัวนี้ โปรดติดต่อเราที่ [ที่อยู่อีเมลสำหรับติดต่อ]</p>

<b>9. การอัปเดตและการแก้ไข</b>
<p>&nbsp;&nbsp;&nbsp;เราขอสงวนสิทธิ์ในการอัปเดตหรือแก้ไขข้อกำหนดและเงื่อนไขเหล่านี้ได้ตลอดเวลา ผู้ใช้จะได้รับแจ้งการเปลี่ยนแปลงใด ๆ ผ่านทางอีเมลหรือผ่านทางเว็บไซต์ของเรา</p>

                    </div>
                </div>
            </div>
            <Footers className="sticky bottom-0 z-50" />
        </div>
    );
};

export default PrivacyPolicy;

