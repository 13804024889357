//BRAND
export const name = 'AIEdCreator';
export const company = 'AIEdCreator';
export const websiteURL = 'https://aiedcreator.edtools.cloud';
export const serverURL = 'https://aiedcreator.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/aiedcreator.appspot.com/o/logo.png?alt=media&token=ec2c3920-2ad8-4de3-bb10-c57bdb47d7ff';
export const razorpayEnabled = false;
export const paypalEnabled = true;
export const stripeEnabled = true;
export const paystackEnabled = false;
export const flutterwaveEnabled = false;

//PRICING :-

//FREE 
export const FreeType = 'ฟรี';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'รายเดือน';
export const MonthCost = 99;
export const MonthTime = 'เดือน';

//YEARLY 
export const YearType = 'รายปี';
export const YearCost = 990;
export const YearTime = 'ปี';

//TESTIMONIAL
export const review = "เครื่องมือสร้างคอร์สเรียนด้วย AI นี้มีประสิทธิภาพ สามารถสร้างหัวข้อที่ตรงกับความต้องการได้อย่างแม่นยำ ช่วยประหยัดเวลาในการพัฒนาเนื้อหาได้อย่างมาก นอกจากนี้ยังช่วยยกระดับคุณภาพและความเกี่ยวข้องของหลักสูตรได้อีกด้วย ถือเป็นเครื่องมือที่ขาดไม่ได้สำหรับนักการศึกษาที่ต้องการสร้างสรรค์ประสบการณ์การเรียนรู้ออนไลน์ที่มีประสิทธิภาพสูง";
export const from = "ธฤต วรรณเสน";
export const profession = 'นักศึกษาระดับบัณฑิตศึกษา มหาวิทยาลัยขอนแก่น';
export const photoURL = 'https://firebasestorage.googleapis.com/v0/b/aiedcreator.appspot.com/o/459028108_8139797679391295_1536976555430143731_n.jpg?alt=media&token=5bffea32-93e1-4836-9b82-acd4729fcf96';

//PAYPAL
export const paypalPlanIdOne = "P-1EM732768S920784HMWKW3OA";
export const paypalPlanIdTwo = "P-8T744865W27080359MWOCE5Q";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";

//STRIPE
export const stripePlanIdOne = "price_1OTo7CSDXmLtVnVeaHIHxqCj";
export const stripePlanIdTwo = "price_1OTo7eSDXmLtVnVeBbn82U5B";

//PAYSTACK
export const paystackPlanIdOne = "PLN_ouqmm8eo6i2k9k8";
export const paystackPlanIdTwo = "PLN_1v1xqb8io9t5lis";
export const amountInZarOne = '170';
export const amountInZarTwo = '1871';

//FLUTTERWAVE
export const flutterwavePlanIdOne = "67960";
export const flutterwavePlanIdTwo = "67961";
export const flutterwavePublicKey = "FLWPUBK_TEST-6ee1faf6460ea587f510a024ac4c2b23-X";

//SOCIAL SIGNIN
export const googleClientId = "233344237389-bo4rs56mt3p59c97j9nah4orj93od3sk.apps.googleusercontent.com";
export const facebookClientId = "8065327730213158";