import React from 'react';
import Header from '../components/header';
import Footers from '../components/footers';
import slide from '../res/img/about.svg'
import { useNavigate } from 'react-router-dom';
import { Button } from 'flowbite-react';
import { company, name } from '../constants';

const About = () => {

    const navigate = useNavigate();

    function redirectContact() {
        navigate("/contact");
    }

    return (
        <div className='h-screen flex flex-col'>
            <Header isHome={false} className="sticky top-0 z-50" />
            <div className='dark:bg-black flex-1'>
                <div className='flex-1 flex flex-col items-center justify-center px-3'>
                    <h1 className="text-6xl font-black mt-14 max-md:text-3xl dark:text-white">เกี่ยวกับเรา</h1>
                            <p className="text-black mb-2 mt-2 max-md:text-center max-md:text-xs dark:text-white">
                                At {company}, we believe in the transformative power of education and the endless possibilities that Artificial Intelligence unlocks.
                                That's why we've developed {name}, a revolutionary SaaS product designed to make course creation seamless, efficient, and intelligent.
                            </p>
                            <p className="text-center text-black mt-6 max-w-2xl font-medium max-md:text-xs dark:text-white">
                    ขอต้อนรับสู่ {name}, เครื่องมือสร้างคอร์สเรียนที่ขับเคลื่อนด้วยเทคโนโลยีปัญญาประดิษฐ์ (AI) ล้ำสมัย พัฒนาโดย {company}!</p>
                </div>
                <div className="px-7 max-md:px-3 justify-center items-center pb-10 dark:bg-black mt-14 ">
                    <div className="flex flex-col md:flex-row items-center">
                        <div className="md:w-1/2 h-full p-4 flex flex-col items-center md:items-start justify-center">
                            <h2 className="text-4xl font-black mb-2 max-md:text-2xl dark:text-white" >AI-Powered Education Creator</h2>
                            <p className="text-black mb-2 mt-2 max-md:text-center max-md:text-xs dark:text-white">
                                ที่ {company}, เราเชื่อมั่นในศักยภาพของการศึกษาในการสร้างความเปลี่ยนแปลง และโอกาสอันไร้ขีดจำกัดที่เทคโนโลยีปัญญาประดิษฐ์ (AI) นำมา ด้วยเหตุนี้ เราจึงได้พัฒนา {name}, ซึ่งเป็นผลิตภัณฑ์ SaaS ที่ล้ำสมัย ออกแบบมาเพื่อให้การสร้างหลักสูตรเป็นไปอย่างราบรื่น มีประสิทธิภาพ และชาญฉลาด
                            </p>
                        </div>
                        <div className="md:w-1/2 h-full">
                            <img
                                src={slide}
                                alt="Your Alt Text"
                                className="w-full h-full object-cover"
                            />
                        </div>
                    </div>
                </div>
                <div className='flex-1 flex flex-col items-center justify-center px-20 max-md:px-3'>
                    <h1 className="text-center text-4xl font-black mt-14 max-md:text-2xl dark:text-white">พันธกิจของเรา</h1>
                    <p className="text-black mb-2 mt-8 text-center max-md:text-xs dark:text-white">
                    พันธกิจของเราคือการเสริมพลังให้นักการศึกษา ผู้เชี่ยวชาญ และองค์กรต่างๆ สร้างประสบการณ์การเรียนรู้ที่โดดเด่นได้อย่างง่ายดาย {name} สะท้อนถึงความมุ่งมั่นของเราในการใช้เทคโนโลยี AI เพื่อลดความซับซ้อนของการพัฒนาหลักสูตรและเปิดมิติใหม่แห่งความเป็นเลิศทางการศึกษา
                    เรามุ่งมั่นที่จะกำหนดมาตรฐานใหม่ในการผสานการศึกษาและเทคโนโลยี ทีมผู้เชี่ยวชาญของเราได้ทุ่มเทพัฒนา {name} มาอย่างต่อเนื่อง เพื่อยกระดับการเรียนรู้ออนไลน์
                    ที่ {name} คุณภาพคือหัวใจสำคัญ เราผสานความก้าวหน้าล่าสุดของ AI เพื่อมอบเครื่องมือที่เหนือความคาดหมายให้กับคุณ"
                    การปรับปรุงนี้ช่วยให้ข้อความกระชับขึ้น โดยยังคงรักษาใจความสำคัญไว้ครบถ้วน ลดการใช้คำฟุ่มเฟือยและการกล่าวซ้ำ ทำให้อ่านง่ายและเข้าใจได้ชัดเจนยิ่งขึ้น
                    </p>
                </div>
                <div className='flex-1 flex flex-col items-center justify-center px-20 max-md:px-3'>
                    <h1 className="text-center text-4xl font-black mt-20 max-md:text-2xl dark:text-white">ร่วมเดินทางสู่การเรียนรู้ไปกับเรา</h1>
                    <p className="text-black mb-2 mt-8 text-center max-md:text-xs dark:text-white">
                    ร่วมเดินทางสู่การสร้างสรรค์นวัตกรรมและความเป็นเลิศทางการศึกษากับ {name} ไม่ว่าคุณจะเป็นนักการศึกษา ผู้เชี่ยวชาญ หรือองค์กรที่ต้องการยกระดับโปรแกรมการเรียนรู้ AIEdCreator พร้อมให้การสนับสนุนคุณในทุกขั้นตอน
                    </p>
                    <Button onClick={redirectContact} className='max-w-xs my-10 items-center justify-center text-center border-black dark:border-white dark:bg-black dark:text-white bg-white text-black font-bold rounded-none w-full enabled:hover:bg-white enabled:focus:bg-white enabled:focus:ring-transparent dark:enabled:hover:bg-black dark:enabled:focus:bg-black dark:enabled:focus:ring-transparent'>ติดต่อเรา</Button>
                </div>
            </div>
            <Footers className="sticky bottom-0 z-50" />
        </div>
    );
};

export default About;
