import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Footers from '../components/footers';
import { serverURL } from '../constants';
import axios from 'axios';
import StyledText from '../components/styledText';

const TermsPolicy = () => {

    const [data, setData] = useState('');

    useEffect(() => {
        async function dashboardData() {
            const postURL = serverURL + `/api/policies`;
            const response = await axios.get(postURL);
            setData(response.data[0].terms)
        }
        if (sessionStorage.getItem('TermsPolicy') === null && sessionStorage.getItem('PrivacyPolicy') === null) {
            dashboardData();
        } else {
            setData(sessionStorage.getItem('TermsPolicy'))
        }
    }, []);

    return (
        <div className='h-screen flex flex-col'>
            <Header isHome={false} className="sticky top-0 z-50" />
            <div className='dark:bg-black flex-1'>
                <div className='flex-1 flex items-center justify-center py-10 flex-col'>
                    <p className='text-center font-black text-4xl text-black dark:text-white'>เงื่อนไขและข้อตกลง</p>
                    <div className='w-4/5 py-20'>
                    <h1>ยินดีต้อนรับสู่ AIEdCreator!</h1>
    <p>โปรดอ่านข้อกำหนดและเงื่อนไขเหล่านี้อย่างละเอียดก่อนใช้งานบริการของเรา</p>

    <b>1. รายละเอียดบริการ</b>
    <p>&nbsp;&nbsp;AIEdCreator เป็นเครื่องมือสร้างหลักสูตรด้วย AI ที่สร้างหลักสูตรตามคำสั่งของผู้ใช้เพื่อวัตถุประสงค์ทางการศึกษา</p>

    <b>2. คุณสมบัติของผู้ใช้</b>
    <p>&nbsp;&nbsp;ผู้ใช้ต้องมีอายุอย่างน้อย 12 ปีเพื่อใช้บริการของเราเพื่อวัตถุประสงค์ทางการศึกษา การใช้บริการของเราถือว่าคุณยืนยันว่าคุณมีคุณสมบัติตรงตามข้อกำหนดนี้</p>

    <b>3. บัญชีผู้ใช้</b>
    <p>&nbsp;&nbsp;เพื่อเข้าถึงบริการของเรา ผู้ใช้ต้องลงทะเบียนโดยให้ที่อยู่อีเมลของตน ผู้ใช้มีหน้าที่รับผิดชอบในการรักษาความลับของข้อมูลบัญชีและกิจกรรมทั้งหมดที่เกิดขึ้นภายใต้บัญชีของตน</p>

    <b>4. การชำระเงิน</b>
    <p>&nbsp;&nbsp;การชำระเงินค่าสมัครสมาชิกสามารถทำได้ผ่าน PayPal และ Stripe เมื่อสมัครสมาชิกบริการของเรา คุณตกลงที่จะปฏิบัติตามข้อกำหนดของผู้ให้บริการชำระเงินที่คุณเลือก</p>

    <b>5. นโยบายการยกเลิกและการคืนเงิน</b>
    <p>&nbsp;&nbsp;ผู้ใช้สามารถยกเลิกการสมัครสมาชิกได้ตลอดเวลา ไม่มีการคืนเงินสำหรับระยะเวลาการสมัครสมาชิกบางส่วน หากต้องการยกเลิกการสมัครสมาชิก ผู้ใช้สามารถดำเนินการได้ผ่านการตั้งค่าบัญชีของตน</p>

    <b>6. ความเป็นส่วนตัวและความปลอดภัยของข้อมูล</b>
    <p>&nbsp;&nbsp;เราให้ความสำคัญกับความเป็นส่วนตัวและความปลอดภัยของข้อมูล ข้อมูลผู้ใช้จะถูกรวบรวมและจัดเก็บในฐานข้อมูล MongoDB เรามีมาตรการรักษาความปลอดภัยตามมาตรฐานอุตสาหกรรมเพื่อปกป้องข้อมูลผู้ใช้จากการเข้าถึง การใช้งาน หรือการเปิดเผยโดยไม่ได้รับอนุญาต</p>

    <b>7. นโยบายการใช้งานที่ยอมรับได้</b>
    <p>&nbsp;&nbsp;ผู้ใช้ตกลงที่จะใช้บริการของเราเพื่อวัตถุประสงค์ทางการศึกษาเท่านั้น และปฏิบัติตามกฎหมายและข้อบังคับที่เกี่ยวข้องทั้งหมด กิจกรรมที่ต้องห้ามรวมถึงแต่ไม่จำกัดเพียง การส่งสแปม การเผยแพร่มัลแวร์ และการละเมิดสิทธิในทรัพย์สินทางปัญญาของผู้อื่น</p>

    <b>8. ความรับผิดและการปฏิเสธความรับผิดชอบ</b>
    <p>&nbsp;&nbsp;เราจะไม่รับผิดชอบต่อความเสียหายหรือการสูญเสียใด ๆ ที่เกิดขึ้นจากการใช้บริการของเรา แม้ว่าเราจะพยายามอย่างเต็มที่เพื่อให้เนื้อหาที่ถูกต้องและเชื่อถือได้ แต่เราไม่สามารถรับประกันความสมบูรณ์หรือความถูกต้องของหลักสูตรที่สร้างขึ้น</p>

    <b>9. การอัปเดตและการแก้ไข</b>
    <p>&nbsp;&nbsp;เราขอสงวนสิทธิ์ในการอัปเดตหรือแก้ไขข้อกำหนดและเงื่อนไขเหล่านี้ได้ตลอดเวลา ผู้ใช้จะได้รับแจ้งการเปลี่ยนแปลงใด ๆ ผ่านทางอีเมลหรือผ่านทางเว็บไซต์ของเรา</p>
                    </div>
                </div>
            </div>
            <Footers className="sticky bottom-0 z-50" />
        </div>
    );
};

export default TermsPolicy;
